<template>
  <b-row>

    <b-col
      cols="12"
      md="12"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <filters-charts
        :tenant-filter.sync="tenantFilter"
        :tenant-options="tenantOptions"
        :incident-filter.sync="incidentFilter"
        :incident-options="incidentOptions"
        :created-at-filter.sync="createdAtFilter"
        :list-filter.sync = "listFilter"
        :list-options = "listOptions"
      />
    </b-col>

    <!-- Per Page -->
    <b-col
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <b-card title="Incidentes" class="full my-0 ml-2">
        <div class="pie-text text-center">
          <h2 class="font-weight-bolder">
            {{total}}
          </h2>
          <span class="font-weight-bold">Total</span>
        </div>

        <e-charts
          ref="chart"
          autoresize
          :options="option"
          theme="theme-color"
          auto-resize
        />

      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <b-card title="Mapa de Calor" class="full my-0 mr-2">
        <GmapMap
          :center="center"
          :zoom="8"
          map-type-id="roadmap"
          ref="map"
          style="right: 16px;
                  left: 16px;
                  top: 60px;
                  bottom: 16px;
                  position: absolute;"
        >
        </GmapMap>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import store from '@/store'
import { computed, ref, onMounted, watch } from '@vue/composition-api'
// import { gmapApi } from 'vue2-google-maps'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import FiltersCharts from './FiltersCharts.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    ECharts,
    FiltersCharts,
  },
  setup() {
      const total = computed(() => store.getters['log_incident/getTotal'])
      const incidentsAll = computed(() => store.getters['log_incident/getItems'])

      window.Echo.connector.socket.on('subscription_error', (channel, data) => {
          console.log(channel, data)
      })

      store.dispatch('tenant/getAll').then(tenants => {
        if (!window.Echo.connector.socket.connected)
            window.Echo.connect()
        tenants.data.forEach(tenant => {
          window.Echo.private(`incident-tenant-${tenant.id}`)
          .listen('.create', data => {
            console.log(data)
            chartData()
          })
        })
      })
      store.dispatch('incident/getAll')

      const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
      const tenantFilter = ref(null)

      const incidentOptions = computed(() => store.getters['incident/getSelectedItems'])
      const incidentFilter = ref(null)
      const createdAtFilter = ref(null)

      store.dispatch('incident_list/getAll')
      const listFilter = ref(null)
      const listOptions = computed(() => store.getters['incident_list/getSelectedItems'])

      const center = computed(() => data.value.length > 0 ? data.value[0] : { lat: 0, lng: 0})

      const map = ref(null)
      const chart = ref(null)

      const heatmap = ref(null)

      const toast = useToast()

      const option = computed(() => ({
                                      tooltip: {
                                        trigger: 'item',
                                        formatter: '{a} <br/>{b}: {c} ({d}%)',
                                      },
                                      legend: {
                                        left: 10,
                                        bottom: '0',
                                      },
                                      series: [{
                                          name: 'Incidentes',
                                          type: 'pie',
                                          avoidLabelOverlap: false,
                                          label: {
                                              show: false,
                                          },
                                          labelLine: {
                                              show: false,
                                          },
                                          data: store.getters['log_incident/getChartItems'],
                                          color: store.getters['log_incident/getChartItemsColors'],
                                        },
                                      ]
                                    }))

      const chartData = (ctx, callback) => {
        store.dispatch('log_incident/getChart', {
          id_incident_list: listFilter.value,
          id_tenant: tenantFilter.value,
          id_incident: incidentFilter.value,
          created_at: createdAtFilter.value,
        })
        .then(response => {
          chart.value.refresh()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error obteniendo la información',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }

      // const google = computed(() => gmapApi())

      // const data = computed(() => incidentsAll.value.map(incident => {
      //   return google.value ? new google.value.maps.LatLng(incident.lat, incident.lng) : [incident.lat, incident.lng]
      // }))

      onMounted(() => {
        chartData()
        watch([data], () => {
          // map.value.$mapPromise.then((map) => {

          //   if(heatmap.value)
          //     heatmap.value.setMap(null);

          //   heatmap.value = new google.value.maps.visualization.HeatmapLayer({
          //     data: data.value,
          //     map: map,
          //   })
          //   heatmap.value.set("radius", 20);
          // })
        })
      })

      watch([tenantFilter, listFilter, incidentFilter, createdAtFilter], () => {
        chartData()
      })

      return {
        total,
        map,
        center,
        tenantOptions,
        tenantFilter,
        listFilter,
        listOptions,
        incidentOptions,
        incidentFilter,
        createdAtFilter,
        chart,
        option,
      }
  }
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin-left: auto;
    margin-right: 10%;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}

.full {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
</style>
